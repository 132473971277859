import React from 'react'

export default function Skills() {
    const { skills } = require("../constants/data.json")
    return (
        <section id="skills">
            <div className='container px-5 py-10 mx-auto'>
                <div className='text-center mb-20'>
                    <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
                        Skills &amp; Technologies
                    </h1>
                    <p className="text-base text-gray-400 leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
                        Among different technologies and languages, I have hands on experiences and basics knowledges on below list:
                    </p>
                </div>
                <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
                    {skills.map((skill) => (
                        <div key={skill} className="p-2 sm:w-1/2 w-full">
                            <div className="bg-gray-800 rounded flex p-4 h-full items-center">
                                {/* <BadgeCheckIcon className="text-green-400 w-6 h-6 flex-shrink-0 mr-4" /> */}
                                <span className="title-font font-medium text-white">
                                    {skill}
                                </span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    )
}
